.App {
  display: flex;
   background-color: #E9E9EE; 
}

.dashboard-tabs button  {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 23px;
  color: #30394A !important;
  margin-right: 20PX;
  text-transform: capitalize;
}
.outerContainer {
  background-color: #E9E9EE;
  height: 100vh;
}

.dashboard-tabs .MuiTabs-indicator {
  background:black;
  height: 4px;
} 

.dateAndTime {
  color: #8D99AC;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
}

.errorMsg {
  color: red;
  text-align: center;
  text-transform: capitalize;
}

.editPopup img:hover,
.deleteIcon:hover {
  cursor: pointer;
}

.gridItem {
  padding-top: 0 !important;
}

.swal2-confirm.swal2-styled {
   background-color: #F7A928 !important;
   border-color: #F7A928 !important;
   border: none !important;
}

.swal2-confirm.swal2-styled:focus {
  box-shadow: none !important;
}
/* ==================Color of tab on select================== */
 /* .css-8zwp3i-MuiButtonBase-root-MuiTab-root.Mui-selected {
 color: #30394a !important; 
 
}  

  .Mui-focused{
  border: none !important;
} 

.MuiSelect-select{
  background-color:transparent!important;
} 

.loginForm .MuiOutlinedInput-notchedOutline {
  background-color: white;
}
.loginForm .MuiInputBase-inputAdornedStart{
  background-color: white;
}
.MuiInputBase-root{
  background-color:white;
}

.loginForm .css-1d3z3hw-MuiOutlinedInput{
  background-color:white;
}
.loginForm .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input{
  background-color: white;
} */

.loginForm input{
border:none
}

.loginForm input:focus-visible{
  outline:none;
}



